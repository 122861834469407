'use client';

import {
  ZENDESK_KEY_EN,
  ZENDESK_KEY_ES,
  ZENDESK_KEY_PT,
} from 'constants/Analytics';
import { language } from 'i18n';

import Script from 'next/script';

interface ZendeskProps {
  lng: language;
}

function Zendesk({ lng }: ZendeskProps): JSX.Element | null {
  const KEYS = {
    pt: ZENDESK_KEY_PT,
    es: ZENDESK_KEY_ES,
    en: ZENDESK_KEY_EN,
  };

  const key = KEYS[lng as keyof typeof KEYS] ?? KEYS.pt;

  return (
    <Script
      id="ze-snippet"
      src={`https://static.zdassets.com/ekr/snippet.js?key=${key}`}
    />
  );
}

export default Zendesk;
